<template>
  <v-row>
    <v-col cols="12" lg="6">
      <account-type-select
        v-model="obAccountConfig.accounttype_id"
        :item.sync="obAccountType"
        required
      />
    </v-col>

    <v-col cols="12" lg="6">
      <payment-method-select
        v-model="obAccountConfig.paymentmethod_id"
        :disabled="!enablePaymentMethod"
        :required="enablePaymentMethod"
      />
    </v-col>

    <v-col cols="12" lg="6">
      <currency-select
        v-model="obAccountConfig.currency_id"
        company-only
        required
      />
    </v-col>

    <v-col cols="12" lg="6">
      <currency-select
        v-model="arCurrencyList"
        company-only
        label="accepted.currencies"
        multiple
      />
    </v-col>

    <v-col cols="12" lg="6">
      <account-select
        v-model="obAccountConfig.account_id"
        :currency="currencyId"
        :disabled="!enableAccounts"
        required
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, VModel, Vue } from "vue-property-decorator";
import { AccountConfig, AccountTypeData } from "@planetadeleste/vue-mc-gw";
import AccountTypeSelect from "@/modules/accounttypes/components/AccountTypeSelect.vue";
import AccountSelect from "@/modules/accounts/components/AccountSelect.vue";
import CurrencySelect from "@/modules/currencies/components/CurrencySelect.vue";
import PaymentMethodSelect from "@/modules/paymentmethods/components/PaymentMethodSelect.vue";

@Component({
  components: {
    AccountTypeSelect,
    AccountSelect,
    CurrencySelect,
    PaymentMethodSelect,
  },
})
export default class AccountconfigsSettings extends Vue {
  @VModel({ type: Object, default: () => new AccountConfig() })
  obAccountConfig!: AccountConfig;
  obAccountType: AccountTypeData | null = null;

  get enablePaymentMethod() {
    return !!this.obAccountType && this.obAccountType.code == "MEDIO_PAGO";
  }

  get enableAccounts() {
    return this.obAccountConfig.any_currency || !!this.currencyId;
  }

  get currencyId(): number | undefined {
    return this.obAccountConfig.get("currency_id");
  }

  get arCurrencyList(): number[] {
    return this.obAccountConfig.get("currencies", []);
  }

  set arCurrencyList(sValue: number[]) {
    this.obAccountConfig.set("currencies", sValue);
  }

  mounted() {
    this.$nextTick(() => {
      this.$_.delay(this.setAccountType, 300);
    });
  }

  setAccountType() {
    this.obAccountType = this.obAccountConfig.get("accounttype", null);
  }
}
</script>
